<template>
    <div class="address-pos">
        <div class="pos">当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-if="breadcrumbType === 'cloud'" :to="{ path: '/cloud' }">云展会</el-breadcrumb-item>
            <el-breadcrumb-item v-else :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item> -->
            <el-breadcrumb-item v-for="(item, index) in levelGroup" :key="index">{{item}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name: 'AddressPosition',
    data() {
        return {
            levelGroup: [],
            breadcrumbType: 'normal'
        }
    },
    created() {
        let routerInfo = this.$route
        // console.log('from address: ', routerInfo)
        const { level, cloud } = routerInfo.meta
        if(level.length && cloud) {
          this.breadcrumbType = 'cloud'
        }
        this.levelGroup = level
    }
}
</script>

<style lang="less">
.address-pos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0;
    .pos {
        font-size: 14px;
        color: #666;
        padding-right: 10px;
    }
}
::v-deep .el-breadcrumb__inner a:hover, .el-breadcrumb__inner .is-link:hover {
  &:hover {
    color: #00ae66 !important;
  }
}
</style>