<template>
    <div class="industry-detail">
        <div class="container">
            <AddressPosition></AddressPosition>
            <div class="main">
                <div class="content">
                    <div class="topic">{{detail.newsTitle}}</div>
                    <div class="sub"><span>文章来源：{{detail.newsSource}}</span><span>发布时间：{{detail.publishedTime}}</span></div>
                    <div class="dtl" v-html="detail.newsContent"></div>
                </div>
                <div class="right-list">
                    <SectionTitle title="最新行业动态" :size="20" more="Industry"></SectionTitle>
                    <RightNews :list="list" @clickitem="initArticle"></RightNews>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressPosition from '@/components/AddressPosition'
import SectionTitle from '@/components/SectionTitle.vue'
import RightNews from '@/components/RightNews.vue'
// import imgSrc from '@/utils/img.js'
// const demoIMG = imgSrc.data()

import { NewsList, NewsDetails } from '@/api'

export default {
    name: 'industryDetail',
    components: {
        AddressPosition,
        SectionTitle,
        RightNews
    },
    data() {
        return {
            titleSize: 20,
            // img: demoIMG.tree,
            detail: '',
            list: []
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0
        NewsList({
            pageNum: 1,
            pageSize: 10,
            newsType: '2',
            // newsState: ''
        }).then(res => {
            let { content } = res.data
            content.map(item=>{
                if (item.publishedTime)
                item.publishedTime = item.publishedTime.split(' ')[0]
                if (item.newsCover.indexOf('http')==-1) {
                item.newsCover = `http://img.miaocang.cc/${item.newsCover}`
                }
                return item
            })
            this.list = content
            this.initArticle()
        })
    },
    methods: {
        initArticle(id) {
            let thisid = id ? id : this.$route.query.article
            NewsDetails({
                id: thisid
            }).then(res=>{
                this.detail = res.data
            })
        }
    }
}
</script>

<style lang="less">
.industry-detail {
    &>.container {
        width: 1200px;
        min-height: 1000px;
        margin: 0 auto;
    }
    .main {
        display: flex;
        justify-content: space-between;
        .content {
            width: 768px;
            .topic {
                font-size: 24px;
                color: #333;
                text-align: center;
                margin: 24px 0;
            }
            .sub {
                font-size: 14px;
                color: #999;
                text-align: center;
                span + span {
                    margin-left: 30px;
                }
            }
            .dtl {
                margin-top: 30px;
                p {
                    font-size: 16px;
                    color: #333;
                    margin-bottom: 20px;
                }
                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;
                }
            }
        }
        .right-list {
            width: 337px;
        }
    }
}
</style>