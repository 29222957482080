<template>
    <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
            <el-image
                class="img"
                :src="item.newsCover"
                fit="cover"
            ></el-image>
            <div class="info">
                <div class="title" @click="toDetail(item.id)">{{item.newsTitle}}</div>
                <div class="date">{{item.publishedTime}}</div>
            </div>
        </div>
    </div>
</template>

<script>
// import bgIMG from '@/assets/home/banner.png'
export default {
    props: {
        list: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            // bgIMG
        }
    },
    mounted() {
        console.log(this.list)
    },
    methods: {
        toDetail(id) {
            this.$emit('clickitem', id)
        }
    }
}
</script>

<style lang="less">
.list {
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .img {
            width: 112px;
            height: 70px;
        }
        .info {
            width: 200px;
            margin-left: 24px;
            .title {
                font-size: 16px;
                color: #333;
                margin-bottom: 6px;
                cursor: pointer;
                &:hover {
                    color: #20a162;
                }
            }
            .date {
                font-size: 14px;
                color: #999;
            }
        }
    }
}
</style>